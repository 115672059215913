import React, { lazy, Suspense} from 'react';
import About from '../../components/About/About';

import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import Utility from '../../components/Utility/Utility';
import RoadMap from '../../components/RoadMap/RoadMap';
import VirtualWorlds from '../../components/VirtualWorlds/VirtualWorlds';
import Team from '../../components/Team/Team';
import FAQ from '../../components/FAQ/FAQ';

const Subscribe = lazy(() => import ('../../components/Subscribe/Subscribe'))

function HomePage() {
  return (
    <Suspense fallback={<div />}>
      <Navbar />
      <Header />
      <About />
      <VirtualWorlds />
      <Utility />
      <RoadMap />
      <Team />
      <FAQ />
      <Subscribe />
    </Suspense>
  )
}

export default HomePage