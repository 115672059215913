import React from 'react';

import './App.styles.scss';
import HomePage from './pages/HomePage/HomePage';

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
